<template>
  <Head>
    <Body class="[--nav-bar-h:4rem] sm:[--nav-bar-h:6rem] md:[--nav-bar-h:8rem] pt-[--nav-bar-h]" />
  </Head>

  <header v-show="!mobileMenuOpen" class="fixed top-0 z-20 w-full h-[--nav-bar-h] bg-black border-b-1 border-slate-600">
    <nav class="flex items-center justify-between px-5 mx-auto text-white max-w-7xl lg:px-8" aria-label="Main navigation">
      <NuxtLink href="/" class="h-[--nav-bar-h] aspect-[1.96] py-1">
        <h1 class="sr-only">Driveczech</h1>
        <img class="h-full invert" src="/images/driveczech-logo-black.svg" width="331" height="169" alt="Driveczech" />
      </NuxtLink>

      <div class="flex justify-end flex-1 max-w-md gap-4">
        <div class="flex-wrap items-center justify-end hidden md:flex gap-x-6 gap-y-4">
          <a href="mailto:info@driveczech.com" class="flex items-center gap-3" aria-label="E-mail">
            <UIcon name="i-heroicons-envelope" />
            info@driveczech.com
          </a>

          <a href="tel:+420603427002" class="flex items-center gap-3 " aria-label="Phone">
            <UIcon name="i-heroicons-phone-20-solid" />
            +420 603 427 002
          </a>

          <Popover v-for="item in navigation" class="relative">
            <PopoverButton class="inline-flex items-center text-sm font-semibold leading-6 gap-x-1">
              <span>{{ item.title }}</span>
              <ChevronDownIcon class="w-5 h-5" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
              <PopoverPanel class="absolute z-10 flex w-screen px-4 mt-5 -translate-x-1/2 left-1/2 max-w-min">
                <div class="w-56 p-4 text-sm font-semibold leading-6 text-gray-900 bg-white shadow-lg shrink rounded-xl ring-1 ring-gray-900/5">
                  <NuxtLink v-for="subitem in item.subitems" :to="subitem.to" class="block p-2 hover:text-indigo-600">{{ subitem.title }}</NuxtLink>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <UButton to="/book" color="primary">Book</UButton>
        </div>

        <UButton to="/book" color="primary" class="md:hidden">Book</UButton>

        <div class="flex md:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </nav>

    <ClientOnly>
      <Dialog as="div" class="md:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-10" />
        <DialogPanel class="fixed inset-y-0 left-0 z-10 w-full p-5 pt-0 overflow-y-auto bg-white dark:bg-slate-900">
          <div class="flex items-center justify-between mx-auto max-w-7xl h-[--nav-bar-h]">
            <div class="flex-1 max-lg:hidden" />

            <NuxtLink href="/" class="h-[--nav-bar-h] aspect-[1.96] py-1">
              <h1 class="sr-only">Driveczech</h1>
              <img class="h-full" src="/images/driveczech-logo-black.svg" width="331" height="169" alt="Driveczech" />
            </NuxtLink>

            <div class="flex justify-end flex-1 max-w-md gap-4">
              <UButton to="/book" color="primary" class="md:hidden">Book</UButton>

              <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700 dark:text-white" @click="mobileMenuOpen = false">
                <span class="sr-only">Close menu</span>
                <XMarkIcon class="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
          </div>

          <nav class="flex flex-col items-center gap-5 mt-10 space-y-2">
            <div class="flex flex-col gap-2 my-2">
              <a href="tel:+420603427002" class="flex items-center gap-3" aria-label="Phone">
                <UIcon name="i-heroicons-phone-20-solid" />
                +420 603 427 002
              </a>
              <a href="mailto:info@driveczech.com" class="flex items-center gap-3" aria-label="E-mail">
                <UIcon name="i-heroicons-envelope" />
                info@driveczech.com
              </a>
            </div>
            <template v-for="menu in navigation">
              <h3 class="m-0 font-sans text-base font-semibold">{{ menu.title }}</h3>
              <template v-for="item in menu.subitems">
                <UButton :to="item.to" variant="ghost" color="gray">{{ item.title }}</UButton>
              </template>
            </template>
          </nav>
        </DialogPanel>
      </Dialog>
    </ClientOnly>
  </header>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'

const navigation = useMenuItems()

const mobileMenuOpen = ref(false)


useRouter().beforeEach(() => {
  mobileMenuOpen.value = false
})
</script>
