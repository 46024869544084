export default defineAppConfig({
  ui: {
    button: {
      base: 'hover:no-underline',
    },
    formGroup: {
      wrapper: 'mb-5'
    },
  },

  businessArea: { // Středočeský kraj +
    minLat: 49.942,
    maxLat: 50.177,
    minLng: 14.219,
    maxLng: 14.707,
  },
})
