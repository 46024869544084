export const useServicesPages = () => [
  {
    title: 'Airport transfers',
    to: '/services/airport-transfers',
    image: '/images/airport-transfers.webp',
    imagePosition: 'top',
    description: 'Enjoy hassle-free Meet & Greet service at any airport in Central Europe.',
  },
  {
    title: 'One way transfers',
    to: '/services/one-way-transfers',
    image: '/images/welcoming-driver-wide.webp',
    imagePosition: '20% 50%',
    description: 'Travel comfortably from/to Prague to any city in Europe with our one-way transfer service.',
  },
  {
    title: 'For businesses',
    to: '/services/business',
    image: '/images/lady-passenger.webp',
    description: 'Chauffeur service for your high-value clients.',
  },
  {
    title: 'Elite Security Services',
    to: '/services/security-driver',
    image: '/images/security-driver.webp',
    description: 'Personalized Security for Celebrities, Politicians, and VIPs',
    ctaTo: '#contact',
  },
  {
    title: 'Customized trips',
    to: '/services/customized-trip',
    image: '/images/prague-charles-bridge.webp',
    description: 'Embark on an unforgettable European multi-day roundtrip or anything else.',
  },
]

export const useMenuItems = () => [
  {
    title: 'Services',
    subitems: useServicesPages(),
  },
  {
    title: 'About',
    subitems: [
      { title: 'Pricing and classes', to: '/about/pricing' },
      { title: 'Our mission', to: '/about/mission' },
      { title: 'Reviews', to: '/about/reviews' },
      { title: 'FAQ', to: '/about/faq' },
    ],
  },
  {
    title: 'Company',
    subitems: [
      { title: 'Terms and conditions', to: '/company/terms' },
      { title: 'Privacy policy', to: '/company/privacy' },
      { title: 'Our team', to: '/company/team' },
      { title: 'Work for us', to: '/company/career' },
      { title: 'Contact', to: '/company/contact' },
    ],
  },
]

export type CarClass = {
  id: string
  title: string
  imageSrc: string
  description: string
  maxPassengers: number
  maxLuggage: number
  mostPopular?: true
  vipExperience?: true
  priceMin: number
  priceKm: number
  priceHour: number
  priceDay: number
}
export const useCarClasses = () => [
  {
    id: 'maybach',
    title: 'Maybach',
    imageSrc: '/images/classes/maybach.webp',
    description: 'Mercedes-Benz Maybach\nMaximum comfort and luxury\nThe largest and most comfortable limousine on the market',
    maxPassengers: 3,
    maxLuggage: 10,
    priceMin: 5700,
    priceKm: 120,
    priceHour: 5700,
    priceDay: 95_000,
    vipExperience: true,
  },
  {
    id: 'first',
    title: 'First Class',
    imageSrc: '/images/classes/first-class.webp',
    description: 'Mercedes-Benz S-class, Audi A8, BMW 7 Series, Lexus LS or similar\nVery spacious limousine',
    maxPassengers: 3,
    maxLuggage: 6,
    priceMin: 2850,
    priceKm: 60,
    priceHour: 2850,
    priceDay: 24_900,
  },
  {
    id: 'business',
    title: 'Business Class',
    imageSrc: '/images/classes/business-class.webp',
    description: 'Mercedes-Benz E-class, Audi A6, BMW 5 Series, Lexus ES or similar\nExecutive sedan',
    maxPassengers: 3,
    maxLuggage: 6,
    priceMin: 1450,
    priceKm: 40,
    priceHour: 1450,
    priceDay: 14_520,
  },
  {
    id: 'van',
    title: 'Business Van',
    imageSrc: '/images/classes/business-van.webp',
    description: 'Mercedes-Benz V-class or similar\nPremium van for a large group or more luggage',
    maxPassengers: 6,
    maxLuggage: 8,
    mostPopular: true,
    priceMin: 1850,
    priceKm: 45,
    priceHour: 1850,
    priceDay: 21_200,
  },
] as CarClass[]

export const useTestimonials = () => [
  {
    authorName: 'TripCCC',
    authorTitle: 'White Rock, Canada',
    authorImageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/23/d7/f6/08/caption.jpg?w=200&h=200&s=1',
    title: 'The Best!',
    body: 'AMAZING! Jan (John) is such a gem. He drove us to 4 countries in 6 days plus airport pick up & drop off. We would have never seen all that we did without him. English speaking & very knowledgeable of all the sites we wanted to see. Jan feels like a friend now, even though he insisted “Chauffeur service” Always wanting to open the car doors for us. I highly recommend him & hope to come back to Czech. Thank you Jan for an amazing 6 days!',
  },
  {
    authorName: 'kamerakaz',
    authorTitle: 'London, UK',
    authorImageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/f6/de/5a/default-avatar-2020-36.jpg?w=100&h=-1&s=1',
    title: 'Excellent trip to Dolni Morava from Prague',
    body: 'Wanted to see the new Sky Bridge in Dolni Morava while on a short trip to Prague. Booking was easy; My online query was responded to immediately and the next morning our driver arrived on time in a nice clean and comfortable car. He was very courteous stopping part way on the 230km journey so we could get refreshments. He also provided water for the journey. Our driver took us right to the ticket office and ensured we purchased the correct tickets and knew exactly where to go. He then waited 3 hours for us to complete all the attractions. We felt in such safe hands! Payment was also simple as the driver accepted cash and cards. We forgot a pair of sunglasses in the back of the car and despite driving over 450kms the driver returned them to our hotel the very same evening. Overall the experience was fantastic.! We had an unbelievable day. Driveczech provided an excellent professional and reliable service where everyone along the way were friendly and went above and beyond to ensure we had the perfect experience. I highly recommend them!',
  },
  {
    authorName: 'Curtainlady34',
    authorTitle: 'Richmond, Virginia',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1b/81/6d/12/curtainlady34.jpg',
    title: 'Seamless',
    body: 'Drive Czech truly made our whole trip seamless. Jan picked us up at the airport in Prague when we arrived from the US and delivered us to our accommodations. He communications with me were very professional and timely. Jan took all of the worry about navigating an unfamiliar city away. During our stay, we also used Drive Czech to deliver us into the Bohemian Paradise for a hiking adventure and to drive us to Berlin for the next phase of our trip. I will use Drive Czech again and recommend them 100% for anyone who needs (or wants) a sweet, safe, responsible, smooth ride!',
  },
  {
    authorName: 'Scarlett785',
    authorTitle: 'USA',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-s/01/2e/70/9a/avatar026.jpg',
    title: 'Wonderful company, even better driver!',
    body: 'Our driver, Milo’s, was incredible! He took us on a journey from Prague to a small czech village to search for where our ancestors used to live when they were liberated. It couldn’t have gone any better. He was patient, kind and helped translate when necessary between Czech and English. We’re grateful for him and the service he provided.',
  },
  {
    authorName: 'Barbg63',
    authorTitle: 'White House, TN',
    authorImageUrl: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1c/96/02/ae/caption.jpg?w=100&h=-1&s=1',
    title: 'Perfect Service!',
    body: 'Such wonderful and easy service! Flexible, too. When we missed our connecting flight and were delayed for several hours, the original driver immediately arranged for another driver to meet us at the airport. Coming to a foreign land where we don’t know the language is quite intimidating. We were also quite exhausted from such a long trip. It was so nice having the driver waiting for us with a sign so we knew he was there for us. He was very professional and helpful with our luggage. He even offered to take our photo in front of where our daughter will be living. I would highly recommend this company! We will probably use again upon a return to Prague.',
  },
  {
    authorName: 'Cory M',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/f1/42/default-avatar-2020-20.jpg',
    title: 'The best tour ever!',
    body: 'Tomas was such a great informative driver for us! His music was great and we all had such a great time. He even went above and beyond and took high quality photos of us with his nice camera and sent them to us. Truly an amazing experience!!',
  },
  {
    authorName: 'clu13',
    authorTitle: 'Charlotte, North Carolina',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/f2/11/default-avatar-2020-23.jpg',
    title: 'Excellent private car service to Prague',
    body: 'Excellent communication while planning, reserving and in Europe. Our guide was very helpful and long ride from Vienna to Prague was comfortable and smooth in the spotless Mercedes E Class. The car had waters, hard candies and tissues. We stopped for a coffee half way and stretch a bit. Highly recommend!',
  },
  {
    authorName: 'hdins3',
    authorTitle: 'JOHNSON CITY',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/05/f2/b3/07/harold-and-nanc-d.jpg',
    title: 'Excellent and Enjoyable Journey!',
    body: 'Our experience was excellent. We wanted a driver to transfer us from Prague to Munich with a stop in Regensburg. The service was impeccable. Our driver, Karel Bartek, was an excellent and accommodating driver. The car was immaculate and completely comfortable. Loved the experience and impressed with the company!',
  },
  {
    authorName: 'Alyssa L',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/eb/43/default-avatar-2020-11.jpg',
    title: 'Highly recommend for long and short drives',
    body: 'I highly recommend DriveCzech for all your transport needs. I chose DriveCzech over other driving agencies because Jan was quick to respond and answer all my questions. The other agencies took days to respond, which made me nervous about how I’d be treated on the day of my trip. I’m very happy I chose DriveCzech for my trip from Prague to Valtice, with a detour through Kutna Hora. Jan showed up early to my hotel, quickly loaded my bags, and made sure I was comfortable before we left. I had the car to myself, which was much better than sharing a minibus with strangers on a hot July day. The car was clean, water was provided, and I felt like a friend was driving me around for the day. As we drove to Kutna Hora, Jan took the time to point out Prague and small town historical sights. I learned special things that I’d never find in a tour book, but a local would know. Jan took pride in the trip to ensure I arrived on time, safely, and happy. We made an extra detour to Lednice, which was a special bonus. He didn’t have to share the extra information or make side stops, but he wanted to be sure I enjoyed my trip. I highly recommend this company for all your trips - short and long. The owner is quick to respond, and I always knew that my luggage was safe while I explored small towns. This service was worth every dollar, especially compared to the other alternative of spending a hot day in a minibus with total strangers.',
  },
  {
    authorName: 'Getaway677421',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/ef/9c/default-avatar-2020-14.jpg',
    title: 'Excellent personal driver',
    body: 'Karel is a professional, conscientious driver who paid attention to his driving rather than his phone. We had several Uber drivers who had made me nervous with their phone usage while driving; I felt very safe with Karel. When asked, Karel gave his opinion on places to see during our limited time. I appreciated and valued his thoughts. His vehicle was clean and he provided water for us. He was professionally dressed. These combined to make a very favorable impression of your company. I would definitely recommend Karel as a driver. Having lived in America, his command of English is excellent, so it was easy to talk to him. My son and I had a great time hiking, one of the highlights of our trip. This could not have been possible without your companys service.',
  },
  {
    authorName: 'Felwa A',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e4/ca/default-avatar-2020-51.jpg',
    title: 'Great experience',
    body: 'The company was super helpful, responsive and professional. They have provided us with the best services and I would definitely recommend them. The driver was punctual with time and knew the routes well.',
  },
  {
    authorName: 'Laura A',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/ed/00/default-avatar-2020-4.jpg',
    title: 'A professional driver and guide',
    body: 'A true professional driver! Everything was perfect, safe, it was more than we expected! We recommend him! Thank you soo much!!!',
  },
  {
    authorName: 'Rachel P',
    authorTitle: 'Houston, Texas',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e3/1f/default-avatar-2020-46.jpg',
    title: 'VIP Experience',
    body: 'I booked a personal driver to customize my trip to Czech and Slovakia. I was extremely pleased with Jan and the service Driveczech provided. Not only did I always have a professional driver, but Jan always strived to make me feel like a VIP. From always being on time, opening my car door and being flexible to change the daily schedule based on my constantly changing my plans. As a single female, I felt safe the entire time traveling abroad. Not only will I happily use Jan and Driceczech in the future, I plan to recommend him to all my family and friends!',
  },
  {
    authorName: 'km0v',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/ee/10/default-avatar-2020-8.jpg',
    title: '11/10, MAGICAL day',
    body: 'Jan was so lovely! I went to a tiny village where my family has roots, and Jan made the trip a truly magical one. He not only did extra research about the village, but also helped to translate for us, so I was able to make family connections that otherwise would have been impossible. 100% worth every penny, today was a day that I will never forget. He was also very comfortable to be around, very professional, and had a great sense of humor. I can say for sure that hiring him is a great choice, and recommend it all the way! Thank you, Jan!',
  },
  {
    authorName: 'P6913VYsheilaa',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/eb/43/default-avatar-2020-11.jpg',
    title: 'Great driver',
    body: 'Jan was a fabulous driver and tour guide throughout our time driving from Berlin to Prague and then Prague to Budapest. He was extremely professional, kind, and with good humour. We would highly recommend Jan for your driving needs to and from Prague. He made our time very comfortable and enjoyable. Tx again Jan',
  },
  {
    authorName: 'Extraordinary518457',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e2/4a/default-avatar-2020-42.jpg',
    title: 'New York Family in Prague',
    body: 'We used driveczech for two trips and both times we had an excellent experience. our driver, Daniel was very helpful with out baggage and carried bags into out apartments on both occassions. all around a very good experience.',
  },
  {
    authorName: 'kathryndL555EG',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e6/ea/default-avatar-2020-54.jpg',
    title: 'Jan helped me find the exact house that my great grandfather was born in!',
    body: 'Jan literally went the extra mile to help me find the house where my great grandfather was born! He met our expectations in all the usual ways: he was on time, polite, the car was beautiful and clean. He was dressed in a suit and provided snacks and water. What made him exceptional was that we could tailor our trip to Cesky Krumlov to include the towns and villages that my ancestors were from. In one instance where I had an address he actually took me to the house where my great grandfather was born! It was rural so he had to drive his Audi on rutted roads but he didn’t hesitate. Once he found the house, he found neighbors to speak with about my family’s descendants who still live in the area! Jan speaks very good English and is well informed. We learned so much about the Czech Republic! I’d give him 10 stars if I could!',
  },
  {
    authorName: 'Lawrence G',
    authorTitle: 'Wilmington, Delaware',
    authorImageUrl: 'https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e4/2d/default-avatar-2020-48.jpg',
    title: 'Efficient way to travel in luxury and see the regular and local sites',
    body: 'We were based in Prague for 10 days. We loved the city but wanted to see other nearby countries. We hired Jan with driveczech for each day trip and for transfer to and from Frankfurt airport. So much easier than flying. We saw so much and so efficiently. Driving on the Autobahn at 120 miles was comfortable in an Audi sedan with a true professional driver. Things we liked best: saw everything efficiently. saw lots of things we never could have seen. driver explained the sites and took us to local spots. no need to move luggage. no need to figure out train schedules or get to airport. drove at our convenience- we could get up early or sleep late. Made for an extremely pleasant experience, much better than any tour or self exploration. Highly recommend and And roughly the same cost when all things considered for air travel and time saving. Worth every dollar.',
  },
]

export const useMoreReviewsLinks = () => [
  {
    title: 'Tripadvisor',
    href: 'https://www.tripadvisor.com/Attraction_Review-g274707-d10898456-Reviews-Driveczech_Your_Personal_Driver-Prague_Bohemia.html#REVIEWS',
    stars: 5,
    reviewsAmount: 42,
  },
  {
    title: 'Google',
    href: 'https://g.page/driveczech',
    stars: 5,
    reviewsAmount: 38,
  },
  {
    title: 'Facebook',
    href: 'https://www.facebook.com/driveczech/reviews',
    stars: 5,
    reviewsAmount: 17,
  },
]
