import { default as faqPqjV52XAimMeta } from "/opt/buildhome/repo/pages/about/faq.vue?macro=true";
import { default as missionaxgc0LhOvZMeta } from "/opt/buildhome/repo/pages/about/mission.vue?macro=true";
import { default as pricingiCOKmpnM2AMeta } from "/opt/buildhome/repo/pages/about/pricing.vue?macro=true";
import { default as reviewsEt4xx2GsGoMeta } from "/opt/buildhome/repo/pages/about/reviews.vue?macro=true";
import { default as carisZMocKUKsMeta } from "/opt/buildhome/repo/pages/book/car.vue?macro=true";
import { default as confirmationkcyXuJcQ7AMeta } from "/opt/buildhome/repo/pages/book/confirmation.vue?macro=true";
import { default as detailsKRlw1WknLGMeta } from "/opt/buildhome/repo/pages/book/details.vue?macro=true";
import { default as indexRbePwFm0GhMeta } from "/opt/buildhome/repo/pages/book/index.vue?macro=true";
import { default as paymentXtTdNYPDh6Meta } from "/opt/buildhome/repo/pages/book/payment.vue?macro=true";
import { default as bookFCoslGx0cvMeta } from "/opt/buildhome/repo/pages/book.vue?macro=true";
import { default as careerHtZMz0DgddMeta } from "/opt/buildhome/repo/pages/company/career.vue?macro=true";
import { default as contacttLkclhydTEMeta } from "/opt/buildhome/repo/pages/company/contact.vue?macro=true";
import { default as privacyYjEhnYkQv5Meta } from "/opt/buildhome/repo/pages/company/privacy.vue?macro=true";
import { default as teamralzzBIQmOMeta } from "/opt/buildhome/repo/pages/company/team.vue?macro=true";
import { default as termsoChYxLstqzMeta } from "/opt/buildhome/repo/pages/company/terms.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as airport_45transfers0vFpgggM7NMeta } from "/opt/buildhome/repo/pages/services/airport-transfers.vue?macro=true";
import { default as businessqDwhodUFs8Meta } from "/opt/buildhome/repo/pages/services/business.vue?macro=true";
import { default as customized_45tripHr0ITXzgF2Meta } from "/opt/buildhome/repo/pages/services/customized-trip.vue?macro=true";
import { default as one_45way_45transfers1xr2W2Ksl5Meta } from "/opt/buildhome/repo/pages/services/one-way-transfers.vue?macro=true";
import { default as security_45driverWmtJpwOE5GMeta } from "/opt/buildhome/repo/pages/services/security-driver.vue?macro=true";
import { default as serviceszlfYtypW62Meta } from "/opt/buildhome/repo/pages/services.vue?macro=true";
import { default as test_45confirm_45mail2LAv4AO8nxMeta } from "/opt/buildhome/repo/pages/test-confirm-mail.vue?macro=true";
export default [
  {
    name: "about-faq",
    path: "/about/faq",
    component: () => import("/opt/buildhome/repo/pages/about/faq.vue")
  },
  {
    name: "about-mission",
    path: "/about/mission",
    component: () => import("/opt/buildhome/repo/pages/about/mission.vue")
  },
  {
    name: "about-pricing",
    path: "/about/pricing",
    component: () => import("/opt/buildhome/repo/pages/about/pricing.vue")
  },
  {
    name: "about-reviews",
    path: "/about/reviews",
    component: () => import("/opt/buildhome/repo/pages/about/reviews.vue")
  },
  {
    name: bookFCoslGx0cvMeta?.name,
    path: "/book",
    component: () => import("/opt/buildhome/repo/pages/book.vue"),
    children: [
  {
    name: "book-car",
    path: "car",
    component: () => import("/opt/buildhome/repo/pages/book/car.vue")
  },
  {
    name: "book-confirmation",
    path: "confirmation",
    component: () => import("/opt/buildhome/repo/pages/book/confirmation.vue")
  },
  {
    name: "book-details",
    path: "details",
    component: () => import("/opt/buildhome/repo/pages/book/details.vue")
  },
  {
    name: "book",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/book/index.vue")
  },
  {
    name: "book-payment",
    path: "payment",
    component: () => import("/opt/buildhome/repo/pages/book/payment.vue")
  }
]
  },
  {
    name: "company-career",
    path: "/company/career",
    component: () => import("/opt/buildhome/repo/pages/company/career.vue")
  },
  {
    name: "company-contact",
    path: "/company/contact",
    component: () => import("/opt/buildhome/repo/pages/company/contact.vue")
  },
  {
    name: "company-privacy",
    path: "/company/privacy",
    component: () => import("/opt/buildhome/repo/pages/company/privacy.vue")
  },
  {
    name: "company-team",
    path: "/company/team",
    component: () => import("/opt/buildhome/repo/pages/company/team.vue")
  },
  {
    name: "company-terms",
    path: "/company/terms",
    component: () => import("/opt/buildhome/repo/pages/company/terms.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/opt/buildhome/repo/pages/services.vue"),
    children: [
  {
    name: "services-airport-transfers",
    path: "airport-transfers",
    component: () => import("/opt/buildhome/repo/pages/services/airport-transfers.vue")
  },
  {
    name: "services-business",
    path: "business",
    component: () => import("/opt/buildhome/repo/pages/services/business.vue")
  },
  {
    name: "services-customized-trip",
    path: "customized-trip",
    component: () => import("/opt/buildhome/repo/pages/services/customized-trip.vue")
  },
  {
    name: "services-one-way-transfers",
    path: "one-way-transfers",
    component: () => import("/opt/buildhome/repo/pages/services/one-way-transfers.vue")
  },
  {
    name: "services-security-driver",
    path: "security-driver",
    component: () => import("/opt/buildhome/repo/pages/services/security-driver.vue")
  }
]
  },
  {
    name: "test-confirm-mail",
    path: "/test-confirm-mail",
    component: () => import("/opt/buildhome/repo/pages/test-confirm-mail.vue")
  }
]